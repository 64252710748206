import React, { useEffect, useState } from "react";
import {
	ArrowLeftIcon,
	PencilSquareIcon,
	ShieldCheckIcon,
} from "@heroicons/react/16/solid";
import {
	CalendarIcon,
	ClockIcon,
	UserIcon,
	UserGroupIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useFetch from "../../../shared/hooks/useFetch";
import Button from "../../../shared/components/FormElements/Button";
import FormattedDate from "../../../shared/components/util/FormattedDate";
import { PaperAirplaneIcon } from "@heroicons/react/16/solid";
import Tooltip from "../../../shared/components/UIElements/Tooltip";
import AnimateIn from "../../../shared/components/Animations/AnimateIn";

const NewSlotBatches = ({ slotDetails, onBack, slotID, selectedPatients }) => {
	const [slot, setSlot] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [patientCount, setPatientCount] = useState(0);
	const navigate = useNavigate();

	const fetchSlot = useFetch();
	const createSlot = useFetch();

	useEffect(() => {
		const getSlotDetails = async () => {
			const response = await fetchSlot(`/api/slots/${slotID}`);
			setSlot(response);
			setPatientCount(selectedPatients.length);
			setIsLoading(false);
		};

		getSlotDetails();
	}, [slotID, selectedPatients]);

	const sendInvitations = async () => {
		try {
			await createSlot(`/api/slots/${slotID}/invite`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(slot.batches),
			});
			navigate(
				`/gaten/${slotID}?uitnodigingenVerstuurd=true&aantalPatienten=${patientCount}`,
			);
		} catch (error) {
			console.error(error);
			toast.error(
				"Er is iets misgegaan bij het versturen van de uitnodigingen",
			);
		}
	};

	return (
		<>
			<AnimateIn>
				<div className="bg-white rounded-2xl border border-slate-200 px-10 py-12 w-2/3 mb-14">
					<h3 className="text-xl leading-6 font-bold text-gray-900 flex items-center gap-2">
						<ShieldCheckIcon className="size-6 text-brand-intense" />
						Dubbelcheck: Klopt alles?
					</h3>

					<p className="font-semibold mt-10 mb-1">De patiënten</p>

					<p className=" text-gray-500">
						{patientCount === 1 ? "Er wordt " : "Er worden "}
						<strong>
							{patientCount} patiënt{patientCount !== 1 && "en"}{" "}
						</strong>
						uitgenodigd voor deze plek.{" "}
						{patientCount > 5 &&
							"Elke 5 minuten worden er nieuwe uitnodigingen verstuurd."}
					</p>

					{slot.behandelaar && (
						<div>
							<div className="flex items-center gap-x-2 mt-10 mb-2">
								<p className="font-semibold">De lege plek</p>
								{/* <Tooltip content="Bewerken">
								<button
									className="text-brand-500 pt-2"
									onClick={onBack}
								>
									<PencilSquareIcon className="size-4" />
								</button>
							</Tooltip> */}
							</div>
							<div className="flex  space-x-3 text-sm text-gray-500">
								<p className="flex items-center bg-slate-50 py-2 px-3 rounded-full border border-slate-200">
									<UserIcon className="h-5 w-5 text-slate-600 mr-3" />
									<span className="text-slate-700">
										{slot.behandelaar.firstName}{" "}
										{slot.behandelaar.lastName}
									</span>
								</p>
								<p className="flex items-center bg-slate-50 py-2 px-3 rounded-full border border-slate-200 text-slate-700">
									<CalendarIcon className="h-5 w-5 text-slate-600 mr-3" />
									<FormattedDate date={slot.date} />
								</p>
								<p className="flex items-center bg-slate-50 py-2 px-3 rounded-full border border-slate-200">
									<ClockIcon className="h-5 w-5 text-slate-600 mr-3" />
									<span className="text-slate-700">
										{slot.startTime} - {slot.endTime} uur (
										{slot.duration} min)
									</span>
								</p>
							</div>
						</div>
					)}

					<p className="mt-10 text-slate-500">
						Klaar om de uitnodigingen te versturen?
					</p>
				</div>
			</AnimateIn>
			<div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 shadow-2xl">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 flex gap-2 justify-start">
					<Button onClick={onBack} ghost iconPrefix={ArrowLeftIcon}>
						Terug
					</Button>
					<Button
						onClick={sendInvitations}
						disabled={patientCount === 0}
						iconSuffix={PaperAirplaneIcon}
					>
						Uitnodigingen versturen{" "}
						<span className="font-normal pl-1 opacity-80">
							({patientCount} patiënt{patientCount !== 1 && "en"})
						</span>
					</Button>
				</div>
			</div>
		</>
	);
};

export default NewSlotBatches;
