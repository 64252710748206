import { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";

export default function useEventSource(endpoint) {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const { getToken } = useAuth();

	useEffect(() => {
		let eventSource;

		const connectToSSE = async () => {
			if (!endpoint) {
				setIsLoading(false);
				return;
			}

			try {
				const token = await getToken();
				const baseURL = process.env.REACT_APP_BACKEND_URL;
				const separator = endpoint.includes("?") ? "&" : "?";
				const url = `${baseURL}${endpoint}${separator}token=${token}`;
				console.log("Token: ", token);
				console.log("URL: ", url);

				eventSource = new EventSource(url, {
					withCredentials: true,
				});

				eventSource.onmessage = (event) => {
					try {
						const parsedData = JSON.parse(event.data);
						setData(parsedData);
						setIsLoading(false);
						eventSource.close();
					} catch (parseError) {
						setError(parseError);
						setIsLoading(false);
					}
				};

				eventSource.onerror = (error) => {
					if (eventSource.readyState === EventSource.CLOSED) {
						setError(error);
						setIsLoading(false);
						eventSource.close();
					}
				};
			} catch (error) {
				setError(error);
				setIsLoading(false);
			}
		};

		connectToSSE();

		return () => {
			if (eventSource) {
				eventSource.close();
			}
		};
	}, [endpoint, getToken]);

	return { data, error, isLoading };
}
