import React from "react";
import inviteSent from "../../assets/animations/inviteSent.json";
import Lottie from "react-lottie";
import { useSearchParams } from "react-router-dom";

const InviteSent = () => {
	const [searchParams] = useSearchParams();
	const shouldShow = searchParams.get("uitnodigingenVerstuurd") === "true";
	const amountOfPatients = parseInt(searchParams.get("aantalPatienten")) || 1;

	if (!shouldShow) return null;

	const title =
		amountOfPatients === 1
			? "Uitnodiging via WhatsApp verstuurd!"
			: `${amountOfPatients} uitnodigingen worden via WhatsApp verstuurd!`;
	const description =
		amountOfPatients === 1
			? "De patiënt heeft een uitnodiging ontvangen en kan deze accepteren of weigeren."
			: `De patiënten worden uitgenodigd en kunnen de afspraak accepteren of weigeren.`;

	return (
		<div className="rounded-3xl bg-slate-100 py-2 px-4 flex items-center gap-x-4">
			<div className="">
				<div className="-m-14 size-[200px] pointer-events-none">
					<Lottie
						options={{
							autoplay: true,
							loop: false,
							animationData: inviteSent,
						}}
					/>
				</div>
			</div>
			<div className="">
				<h4 className="font-semibold">{title}</h4>
				<p className="text-sm text-gray-500">{description}</p>
			</div>
		</div>
	);
};

export default InviteSent;
