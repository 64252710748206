import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MainLogo from "../../shared/components/Branding/MainLogo";
import NewPatientForm from "../components/creation-wizard/NewPatientForm";
import CloseWizard from "../../slots/components/creation-wizard/CloseWizard";
import backgroundImage from "../../assets/Background.png";
import NewPatientSteps from "../components/creation-wizard/NewPatientSteps";
import ChoosePatientType from "../components/ChoosePatientType";

const NewPatientPage = ({ isEditing, initialPatient }) => {
	const [currentStep, setCurrentStep] = useState(0);
	const [fromApp, setFromApp] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get("fromApp") === "true") {
			setCurrentStep(1);
			setFromApp(true);
		}
	}, [searchParams]);

	const steps = [
		{ name: "Basisgegevens", status: "complete" },
		{
			name: "Details",
			status: currentStep === 0 ? "current" : "complete",
		},
	];

	const backgroundImageUrl = `${process.env.REACT_APP_FRONTEND_URL}${backgroundImage}`;

	return (
		<div
			className="bg-slate-100 min-h-screen pb-20 flex flex-col"
			style={{
				backgroundImage: `url(${backgroundImageUrl})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "contain",
				backgroundPosition: "right bottom",
			}}
		>
			<div className="flex-grow">
				<div className="flex justify-between pt-14 pb-14 items-center max-w-7xl mx-auto px-4 sm:px-6">
					<div className="w-1/3 justify-start hidden md:flex gap-6 items-center">
						<MainLogo />
						<div className="-mb-7">
							<ChoosePatientType />
						</div>
					</div>

					<div className="w-1/2 md:w-1/3 flex justify-center">
						<NewPatientSteps
							steps={steps}
							currentStep={currentStep}
						/>
					</div>

					<div className="w-1/3 flex justify-end">
						<CloseWizard
							currentStep={currentStep}
							to="/patienten/all"
						/>
					</div>
				</div>
				<div className="max-w-7xl mx-auto px-4 sm:px-6">
					<NewPatientForm
						setCurrentStep={setCurrentStep}
						fromWizard={true}
						fromDesktopApp={fromApp}
						initialPatient={initialPatient}
						isEditing={isEditing}
					/>
				</div>
			</div>
		</div>
	);
};

export default NewPatientPage;
