import React, { useState } from "react";
import { Menu } from "@headlessui/react";
import {
	ChevronDownIcon,
	QueueListIcon,
	ArrowPathIcon,
	CheckIcon,
	InformationCircleIcon,
	ArrowsRightLeftIcon,
} from "@heroicons/react/16/solid";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useFeatureFlagEnabled } from "posthog-js/react";

import classNames from "classnames";
import Modal from "../../shared/components/UIElements/Modal";

const patientTypeOptions = [
	{
		title: "Snel bellen lijst",
		shortDescription: "Patiënten die zelf eerder willen",
		slug: "nieuw",
		icon: QueueListIcon,
		longDescription:
			"Patiënten die zelf graag eerder een afspraak willen. Deze patiënten blijven in Snelterecht staan totdat ze een afspraak hebben.",
	},
	{
		title: "Naar voren halen",
		shortDescription: "Vraag of patiënten eerder kunnen",
		slug: "verplaatsen",
		icon: ArrowsRightLeftIcon,
		isBeta: true,
		longDescription:
			"Als je door wijzigingen in de planning een patiënt wilt vragen of ze ook op een ander moment kunnen komen. Deze patiënt ontvangt éénmalig een uitnodiging en wordt daarna niet meer opgeroepen voor andere plekken.",
	},
];

const ChoosePatientType = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const [showInfoModal, setShowInfoModal] = useState(false);

	const flagEnabled = useFeatureFlagEnabled("reschedule_patients");

	const isWachtlijst = !location.pathname.includes("verplaatsen");

	const handleOptionSelect = (path) => {
		const params = new URLSearchParams(searchParams);
		const queryString = params.toString();
		navigate(`/patienten/${path}${queryString ? `?${queryString}` : ""}`);
	};

	if (!flagEnabled) {
		return null;
	}

	return (
		<>
			<div className="w-full max-w-xs mb-8">
				<div className="flex items-center gap-2">
					<Menu as="div" className="relative inline-block text-left">
						<Menu.Button className="inline-flex w-full justify-between items-center border border-slate-300 rounded-full px-3 py-1.5 text-sm font-medium text-gray-700  hover:bg-white transition-all duraiton-200 focus:outline-none focus:ring-2 focus:ring-brand-intense">
							{isWachtlijst
								? "Snel bellen lijst"
								: "Naar voren halen"}
							<ChevronDownIcon
								className="h-5 w-5 ml-2 -mr-1 text-gray-400"
								aria-hidden="true"
							/>
						</Menu.Button>

						<Menu.Items className="absolute left-0 z-10 mt-1.5 w-72 origin-top-left rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="py-1">
								{patientTypeOptions.map((option) => (
									<Menu.Item key={option.slug}>
										{({ active }) => (
											<button
												onClick={() =>
													handleOptionSelect(
														option.slug,
													)
												}
												className={classNames(
													active ? "bg-gray-100" : "",
													"flex w-full items-center px-4 py-3 text-sm text-gray-700 justify-between",
												)}
											>
												<div className="flex items-center">
													<option.icon
														className="mr-3 h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
													<div className="text-left">
														<div className="font-medium flex items-center gap-1">
															<span>
																{option.title}
															</span>
															{option.isBeta && (
																<span className="text-xs bg-brand-50 text-brand-intense px-2 py-1 rounded-full">
																	Beta
																</span>
															)}
														</div>
														<div className="text-gray-500 text-xs">
															{
																option.shortDescription
															}
														</div>
													</div>
												</div>
												{(isWachtlijst
													? option.slug === "nieuw"
													: option.slug ===
														"verplaatsen") && (
													<CheckIcon className="h-5 w-5 text-brand-intense" />
												)}
											</button>
										)}
									</Menu.Item>
								))}
								<div className="border-t border-gray-100">
									<Menu.Item>
										{({ active }) => (
											<button
												onClick={() =>
													setShowInfoModal(true)
												}
												className={classNames(
													"flex w-full items-center justify-center px-6 pt-2 pb-1.5 text-xs text-gray-500 hover:text-gray-800 transition-colors duration-200",
												)}
											>
												<InformationCircleIcon className="mr-1.5 size-3.5 text-slate-400 transition-colors duration-200 group-hover:text-gray-800" />
												<span className="transition-colors duration-200">
													Wat is het verschil?
												</span>
											</button>
										)}
									</Menu.Item>
								</div>
							</div>
						</Menu.Items>
					</Menu>
				</div>
			</div>

			<Modal open={showInfoModal} setOpen={setShowInfoModal}>
				<div className="px-6 pb-6 -mt-5">
					<h3 className="text-lg font-bold leading-6 text-gray-900 mb-4">
						Wat is het verschil?
					</h3>
					<div className="space-y-8">
						{patientTypeOptions.map((option) => (
							<div key={option.slug}>
								<h4 className="font-medium mb-2 flex items-center">
									<option.icon className="h-5 w-5 mr-2 text-brand-intense" />
									{option.title}
								</h4>
								<p className="text-slate-600 text-sm">
									{option.longDescription}
								</p>
							</div>
						))}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ChoosePatientType;
