import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { useUser } from "@clerk/clerk-react";
import {
	CalendarIcon,
	CheckCircleIcon,
	UserIcon,
} from "@heroicons/react/20/solid";
import useFetch from "../../../shared/hooks/useFetch";
import FormattedDate from "../../../shared/components/util/FormattedDate";
import StatusPill from "../../../slots/components/StatusPill";
import classNames from "classnames";
import { ClockIcon } from "@heroicons/react/20/solid";
import SkeletonLoadingItem from "../../../shared/components/UIElements/SkeletonLoadingItem";
import Label from "../../../shared/components/FormElements/Label";
import Button from "../../../shared/components/FormElements/Button";
import { PlusIcon } from "@heroicons/react/16/solid";

const SelectSlots = ({ name, label, ...props }) => {
	const fetchData = useFetch();
	const { user } = useUser();
	const [field, meta, helpers] = useField(name);
	const [isLoading, setIsLoading] = useState(true);
	const [slots, setSlots] = useState([]);

	useEffect(() => {
		const fetchSlots = async () => {
			const data = await fetchData(`/api/slots/available/${user.id}`);
			setSlots(data);
			setIsLoading(false);
		};
		fetchSlots();
	}, [fetchData, user.id]);

	const handleSlotSelect = (slotId) => {
		// If clicking the currently selected slot, deselect it
		if (field.value === slotId) {
			helpers.setValue(null);
		} else {
			// Otherwise select the new slot
			helpers.setValue(slotId);
		}
	};

	const isSlotSelected = (slotId) => {
		return field.value === slotId;
	};

	if (!isLoading && slots.length === 0) {
		return (
			<div className="mt-6">
				<div className="flex items-center justify-between">
					<Label name={name} label={label} {...props} />
				</div>
				<p className="text-gray-500 text-sm mb-2">
					Je hebt geen openstaande plekken.
				</p>
				<Button
					ghost
					to="/gaten/nieuw"
					size="medium"
					target="_blank"
					iconPrefix={CalendarIcon}
				>
					Plek melden
				</Button>
			</div>
		);
	}

	return (
		<div className="mt-6">
			<div className="flex items-center justify-between">
				<Label name={name} label={label} {...props} />
			</div>
			{meta.touched && meta.error && (
				<p className="text-red-500 text-sm mb-2">{meta.error}</p>
			)}
			<div className="space-y-4 pt-1">
				{isLoading && (
					<div className="space-y-4">
						{[...Array(3)].map((_, i) => (
							<SkeletonLoadingItem
								key={i}
								width="w-full"
								height="h-24"
								rounded="3xl"
								color="slate-200"
							/>
						))}
					</div>
				)}
				{slots.map((slot) => (
					<div
						key={slot._id}
						onClick={() => handleSlotSelect(slot._id)}
						className={classNames(
							"bg-white px-4 py-5 rounded-3xl border shadow-sm hover:shadow cursor-pointer transition-all duration-300",
							{
								"border-brand-600 border-2": isSlotSelected(
									slot._id,
								),
								"border-gray-200": !isSlotSelected(slot._id),
							},
						)}
					>
						<div className="flex gap-x-4">
							<div>
								{isSlotSelected(slot._id) ? (
									<CheckCircleIcon
										className={classNames(
											"size-7 transition-colors duration-200",
											isSlotSelected(slot._id)
												? "text-brand-600"
												: "text-gray-300",
										)}
									/>
								) : (
									<button className="size-6 mt-[2px] ml-[2px] rounded-full border-2 border-gray-300 transition-colors duration-200"></button>
								)}
							</div>
							<div className="flex-grow">
								<div className="flex items-center justify-between">
									<div>
										<p className="font-semibold">
											<FormattedDate date={slot.date} />
										</p>

										<div className="mt-1 flex items-center gap-x-4 text-sm text-slate-500">
											<p className="flex items-center gap-x-2">
												<ClockIcon className="size-4 text-slate-500" />
												{slot.startTime} -{" "}
												{slot.endTime} uur
											</p>
											<p className="flex items-center gap-x-1.5">
												<UserIcon className="size-4 text-slate-500" />
												{slot.behandelaar?.firstName}{" "}
												{slot.behandelaar?.lastName}
											</p>
										</div>
									</div>
									<StatusPill
										status={slot.status}
										size="medium"
									/>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default SelectSlots;
