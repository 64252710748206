import React from "react";
import SelectSlots from "./SelectSlots";
import { CalendarIcon } from "@heroicons/react/16/solid";
import RadioGroup from "../../../shared/components/FormElements/RadioGroup";

const RescheduleFormStepTwo = () => {
	return (
		<div>
			<h3 className="text-xl leading-6 font-bold text-gray-900 flex items-center gap-2">
				<CalendarIcon className="size-5 text-brand-intense" />
				Uitnodigen
			</h3>

			<SelectSlots
				name="slot"
				label="Voor welke plek wil je de patiënt uitnodigen?"
				required
			/>
			<RadioGroup
				name="originalAppointmentDate"
				label="Wanneer staat de originele afspraak van de patiënt?"
				options={[
					{ label: "Op dezelfde dag", value: "sameDay" },
					{ label: "Op een andere dag", value: "otherDay" },
				]}
				required
			/>
		</div>
	);
};

export default RescheduleFormStepTwo;
