import React, { useRef } from "react";
import Lottie from "lottie-react";

import useFetch from "../../shared/hooks/useFetch";
import { useTaskContext } from "../context/TaskContext";

import Tooltip from "../../shared/components/UIElements/Tooltip";

import checkTask from "../../assets/animations/checkTask.json";

const CheckTask = ({ task, isChecked, setTasks }) => {
	const updateTask = useFetch();
	const { triggerTaskUpdate } = useTaskContext();
	const [localIsChecked, setLocalIsChecked] = React.useState(isChecked);
	const lottieRef = useRef();

	const toggleTaskStatus = async () => {
		setLocalIsChecked(!localIsChecked);

		try {
			const results = await updateTask(`/api/tasks/${task._id}`, {
				method: "PUT",
				body: JSON.stringify({
					isChecked: !localIsChecked,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			});
			setTasks((prevTasks) =>
				prevTasks.map((prevTask) =>
					prevTask._id === task._id ? results : prevTask,
				),
			);
			if (lottieRef.current) {
				lottieRef.current.setSpeed(1.2);
			}
			triggerTaskUpdate();
		} catch (error) {
			setLocalIsChecked(localIsChecked);
		}
	};

	return (
		<div onClick={toggleTaskStatus} className="cursor-pointer">
			{localIsChecked ? (
				<div className="size-[100px] -m-[34px]">
					<Lottie
						lottieRef={lottieRef}
						animationData={checkTask}
						loop={false}
						autoplay={true}
					/>
				</div>
			) : (
				<Tooltip content="Taak afvinken" id={`taak-${task._id}`}>
					<div className="h-8 w-8 rounded-full border-2 border-slate-300 flex items-center justify-center"></div>
				</Tooltip>
			)}
		</div>
	);
};

export default CheckTask;
