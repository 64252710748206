import mainLogo from "../../../assets/Logo.png";

const MainLogo = ({ className }) => {
	return (
		<img
			src={mainLogo}
			className={`h-9 w-auto ${className}`}
			alt="Snelterecht"
		/>
	);
};

export default MainLogo;
