import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../../shared/hooks/useFetch";
import Table from "../../../shared/components/Tables/Table";
import FormattedDate from "../../../shared/components/util/FormattedDate";
import Heading from "../../../shared/components/UIElements/Heading";
import { DateRangePicker } from "@tremor/react";
import ProfileImage from "../../../users/components/ProfileImage";
import { Link } from "react-router-dom";
import { ChatBubbleLeftIcon } from "@heroicons/react/20/solid";

const FreeformMessages = () => {
	const { user } = useUser();
	const fetchData = useFetch();
	const [dateRange, setDateRange] = useState({
		from: new Date(),
		to: new Date(),
	});
	const [messages, setMessages] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchMessages = async () => {
			try {
				const data = await fetchData(
					`/api/admin/messages/freeform?adminId=${
						user.id
					}&from=${dateRange.from.toISOString()}&to=${dateRange.to.toISOString()}`,
				);
				setMessages(data);
			} catch (error) {
				console.error("Error fetching messages:", error);
			}
			setIsLoading(false);
		};

		fetchMessages();
	}, [fetchData, user.id, dateRange]);

	return (
		<div>
			<div className="flex items-center justify-between mb-10">
				<Heading>Vrije berichten</Heading>
				<DateRangePicker
					value={dateRange}
					onValueChange={setDateRange}
				/>
			</div>

			<Table data={messages} isLoading={isLoading}>
				<th
					scope="col"
					className="pl-6 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(row) => (
						<div className="flex items-center gap-x-4">
							<ProfileImage name={row.practice?.name} />
							<div>
								<Link
									to={`/admin/practice/?id=${row.practice?._id}`}
									className="hover:underline"
								>
									<p className="font-medium text-black">
										{row.practice?.name}
									</p>
								</Link>
								<p className="text-sm text-gray-500 flex items-center gap-x-1">
									{row.patient?.patientNumber}
									{row.patient?.type === "reschedule" && (
										<p className="text-xs text-orange-700 font-medium">
											🔀 Reschedule
										</p>
									)}
									{row.patient?.status === "deleted" && (
										<p className="text-xs text-red-700 font-medium">
											🗑️ Verwijderd
										</p>
									)}
								</p>
							</div>
						</div>
					)}
				>
					Practice
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(row) => (
						<div className="flex items-start gap-x-3">
							<ChatBubbleLeftIcon className="w-5 h-5 text-gray-400 mt-1" />
							<div className="bg-gray-100 rounded-lg p-3 max-w-lg">
								<p className="whitespace-pre-wrap">
									{row.message}
								</p>
								<p className="text-xs text-gray-500 mt-1">
									<FormattedDate
										date={row.createdAt}
										relative={true}
									/>
								</p>
							</div>
						</div>
					)}
				>
					Bericht
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(row) => (
						<div>
							<p>
								{row.patient?.messages?.length || 0}{" "}
								uitnodigingen totaal
							</p>
							<p className="text-sm text-gray-500">
								{row.patient?.messages?.filter(
									(m) => m.response === "accepted",
								).length || 0}{" "}
								geaccepteerd
							</p>
						</div>
					)}
				>
					Stats
				</th>
			</Table>
		</div>
	);
};

export default FreeformMessages;
