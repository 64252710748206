import React, { useEffect, useRef } from "react";

const Video = ({ privateHash, videoID, title }) => {
	return (
		<>
			<div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
				<iframe
					src={`https://player.vimeo.com/video/${videoID}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&h=${privateHash}`}
					frameborder="0"
					allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
					style={{
						position: "absolute",
						top: "0",
						left: "0",
						width: "100%",
						height: "100%",
					}}
					className="rounded-2xl shadow-lg"
					title={title}
				></iframe>
			</div>
		</>
	);
};

export default Video;
