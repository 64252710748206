import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";

import useFetch from "../../../shared/hooks/useFetch";
import { useNavigate } from "react-router-dom";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	PlusIcon,
} from "@heroicons/react/16/solid";
import Button from "../../../shared/components/FormElements/Button";
import PatientBasicInfo from "./PatientBasicInfo";
import PatientAdvancedInfo from "./PatientAdvancedInfo";
import { validationSchema, validationSchemaStepOne } from "./validationSchema";
import { handleSubmit } from "./submitHandler";
import { demoPatient } from "./demoPatient";
import PrefilledFromDesktopInfo from "./PrefilledFromDesktopInfo";
import DesktopAppNotice from "../DesktopAppNotice";
import AnimateIn from "../../../shared/components/Animations/AnimateIn";

const NewPatientForm = ({
	isEditing = false,
	initialPatient,
	initialValues = { phoneNumber: "+31 6" },
	fromDesktopApp = false,
	fromWizard = false,
	setCurrentStep,
}) => {
	const { user } = useUser();
	const navigate = useNavigate();
	const createPatient = useFetch();

	const [step, setStep] = useState(1);
	const [treatments, setTreatments] = useState([]);
	const [behandelaarTreatments, setBehandelaarTreatments] = useState([]);
	const [availableTreatments, setAvailableTreatments] = useState([]);
	const [defaultLanguage, setDefaultLanguage] = useState("nl");
	const [showErrors, setShowErrors] = useState(false);

	const [prefilledValues, setPrefilledValues] = useState({});
	const [isLoadingIntegrationResult, setIsLoadingIntegrationResult] =
		useState(true);
	const [foundPhoneNumbers, setFoundPhoneNumbers] = useState([]);

	const [currentValidationSchema, setCurrentValidationSchema] = useState(
		validationSchemaStepOne,
	);

	useEffect(() => {
		if (user?.unsafeMetadata?.preferences?.language) {
			setDefaultLanguage(user.unsafeMetadata.preferences.language);
		}
	}, [user]);

	useEffect(() => {
		const fetchTreatments = async () => {
			try {
				const response = await createPatient("/api/treatments");
				const formattedResponse = response.map((treatment) => ({
					label: treatment.name,
					value: treatment._id,
					possibleDurations: treatment.possibleDurations,
				}));

				setTreatments(formattedResponse);
			} catch (error) {
				console.error("Error fetching treatments:", error);
				toast.error("Fout bij het ophalen van de behandelingen", {
					position: "bottom-center",
				});
			}
		};

		fetchTreatments();
	}, []);

	useEffect(() => {
		const filteredTreatments = treatments.filter((treatment) =>
			behandelaarTreatments.includes(treatment.value),
		);
		setAvailableTreatments(filteredTreatments);
	}, [behandelaarTreatments, treatments]);

	useEffect(() => {
		if (fromDesktopApp) {
			setStep(2);
		}
	}, [fromDesktopApp]);

	useEffect(() => {
		setCurrentValidationSchema(
			step === 1 ? validationSchemaStepOne : validationSchema,
		);
	}, [step]);

	const getInitialValues = () => {
		let values;
		if (!fromDesktopApp) {
			const isStaging = process.env.REACT_APP_STAGING === "true";
			const shouldUseDemoPatient =
				isStaging && !isEditing && !fromDesktopApp;

			if (shouldUseDemoPatient) {
				values = demoPatient;
			} else if (isEditing) {
				values = initialPatient;
			} else {
				values = initialValues;
			}
		}

		return {
			...values,
			language: !isEditing ? defaultLanguage : values.language,
		};
	};

	return (
		<Formik
			initialValues={getInitialValues()}
			enableReinitialize
			validationSchema={currentValidationSchema}
			onSubmit={(values, actions) => {
				handleSubmit(
					fromDesktopApp,
					values,
					actions,
					step,
					setStep,
					fromWizard,
					setCurrentStep,
					isEditing,
					initialPatient,
					user,
					createPatient,
					navigate,
				);
			}}
		>
			{({ values, isSubmitting, setFieldValue, errors, touched }) => (
				<>
					<div className="flex gap-x-5">
						<Form autoComplete="off">
							<div className="flex flex-wrap content-start">
								{step === 1 && (
									<AnimateIn>
										<div className="flex flex-col gap-y-5">
											<div className="bg-white rounded-2xl shadow p-10 pt-12 max-w-xl border border-slate-200 mb-5">
												<PatientBasicInfo
													values={values}
													setFieldValue={
														setFieldValue
													}
													isEditing={isEditing}
												/>
											</div>
											{!fromDesktopApp && !isEditing && (
												<div className="max-w-xl">
													<DesktopAppNotice />
												</div>
											)}
										</div>
									</AnimateIn>
								)}
								{step === 2 && (
									<AnimateIn>
										{!isLoadingIntegrationResult &&
											(!prefilledValues.firstName ||
												!prefilledValues.lastName ||
												!prefilledValues.phoneNumber ||
												!prefilledValues.patientnummer) && (
												<div className="bg-white rounded-2xl shadow p-10 pt-12 border border-slate-200 mb-5">
													<PatientBasicInfo
														values={values}
														setFieldValue={
															setFieldValue
														}
														isEditing={isEditing}
													/>
												</div>
											)}
										<div className="bg-white rounded-2xl shadow p-10 pt-12 border border-slate-200 min-w-[600px] mb-32">
											<PatientAdvancedInfo
												values={values}
												setFieldValue={setFieldValue}
												fromDesktopApp={fromDesktopApp}
												availableTreatments={
													availableTreatments
												}
												treatments={treatments}
												setBehandelaarTreatments={
													setBehandelaarTreatments
												}
												isLoadingIntegrationResult={
													isLoadingIntegrationResult
												}
												foundPhoneNumbers={
													foundPhoneNumbers
												}
												isEditing={isEditing}
											/>
										</div>
									</AnimateIn>
								)}
							</div>

							<div className="fixed bottom-0 left-0 right-0 ">
								{showErrors &&
									Object.keys(errors).length > 0 && (
										<div className="bg-orange-50 border-b border-gray-200 p-4 shadow-2xl">
											<div className="max-w-7xl mx-auto px-4 sm:px-6 flex gap-x-2">
												<p className="text-orange-800">
													{Object.keys(errors)
														.map(
															(key) =>
																errors[key],
														)
														.join(", ")}
												</p>
											</div>
										</div>
									)}
								<div className="bg-white border-t border-gray-200 p-4 shadow-2xl">
									<div className="max-w-7xl mx-auto px-4 sm:px-6 flex gap-x-2">
										{step === 2 && (
											<Button
												ghost
												iconPrefix={ArrowLeftIcon}
												type="button"
												onClick={() => {
													setStep(1);
													if (fromWizard)
														setCurrentStep(0);
												}}
											>
												Terug
											</Button>
										)}
										{step === 1 && (
											<Button
												onClick={() => {
													const isStepOneValid =
														Object.keys(
															validationSchemaStepOne.fields,
														).every(
															(field) =>
																!errors[
																	field
																] ||
																!touched[field],
														);
													if (isStepOneValid) {
														setStep(2);
														if (fromWizard)
															setCurrentStep(1);
													} else {
														setShowErrors(true);
														toast.error(
															"Vul alle verplichte velden in voordat je doorgaat.",
															{
																position:
																	"bottom-center",
															},
														);
													}
												}}
												disabled={isSubmitting}
												iconSuffix={ArrowRightIcon}
											>
												Volgende
											</Button>
										)}
										{step === 2 && (
											<Button
												type="submit"
												disabled={
													isSubmitting ||
													(showErrors &&
														Object.keys(errors)
															.length > 0)
												}
												iconSuffix={PlusIcon}
												onClick={() => {
													setShowErrors(true);
												}}
											>
												{isEditing
													? "Patiënt updaten"
													: "Patiënt toevoegen"}
											</Button>
										)}
									</div>
								</div>
							</div>
						</Form>
						{fromDesktopApp && (
							<PrefilledFromDesktopInfo
								setStep={setStep}
								setPrefilledValues={setPrefilledValues}
								setIsLoadingIntegrationResult={
									setIsLoadingIntegrationResult
								}
								setFoundPhoneNumbers={setFoundPhoneNumbers}
								isVisible={step === 2}
							/>
						)}
					</div>
				</>
			)}
		</Formik>
	);
};

export default NewPatientForm;
